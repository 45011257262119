<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 240 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1 relative">
            <v-icon left>
              query_stats
            </v-icon>

            Audience Credibility

            <span class="primary white--text beta-label">NEW</span>
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card min-width="240">
        <v-card-title class="d-md-none">
          Audience Credibility Class
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                query_stats
              </v-icon>

              Select a value
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Filter by the audience credibility class. <br />
                Medium: 40%+ real and active audience. <br />
                High and Best: 70%+ real and active audience.
              </span>
            </v-tooltip>
          </div>

          <v-radio-group
            v-model="form.selectedIndex"
            @change="submitForm"
            hide-details
          >
            <v-radio
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <!-- Show the selector here -->
          <div class="pt-4">
            <weight-selector
              :value="form.selectedIndex === null ? form.value : null"
              @input="(v) => form.value = v"
              style="width: unset; max-width: 240px;"
              @change="form.selectedIndex = null; submitForm()"
            />
          </div>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Used throughout the code
const filterName = "audienceCredibilityClass"

// Import the child components
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// The default form value
const originalForm = () => ({
  selectedIndex: -1,
  value: null
})

// Export the SFC
export default {
  // Name of the component
  name: "FilterAudienceCredibilityClass",

  // Register children components
  components: {
    WeightSelector
  },

  // Register data variables
  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    options: [
      {
        label: "Low",
        value: ["bad", "low"]
      },
      // {
        // label: "Medium",
        // value: 0.4
        // value: ["normal"],
      // },
      {
        label: "High and Best",
        value: 0.7
        // value: ["high", "best"],
      }
    ],
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFilterByType"](filterName))
    }
  },

  methods: {
    /**
     * Takes an item and generates the label for it
     */
    // computeFilterText({ label }) {
    computeFilterText() {
      return `Audience Credibility: ${this.form.selectedIndex !== null ? this.options[this.form.selectedIndex].label : '> ' + (this.form.value * 100) + '%'}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    async submitForm() {
      this.$store.dispatch("influencerDiscovery/replaceFilter", {
        type: filterName,
        data: {
          color: "pink lighten-5",
          icon: "query_stats",
          iconColor: "pink",
          text: this.computeFilterText(),
          inputs: {
            value: this.form.selectedIndex !== null ? this.options[this.form.selectedIndex].value : this.form.value
          }
        }
      })
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      // const index = this.options.findIndex((search) => search.value === e.detail.item.data.inputs.value)
      this.form.selectedIndex = index
      this.form.value = e.detail.item.data.inputs.value
    })
  }
}
</script>

<style lang="stylus" scoped>
.relative
  position relative

.beta-label
  position absolute
  top -0.7rem
  left 2rem
  font-size 0.75em
  font-weight bold
  border 1px solid
  line-height 1
  padding 1px 2px
</style>
